<script lang="ts" setup>
import type { PublicationSummary } from '~/components/card/index.vue'

const props = withDefaults(
	defineProps<{
		title?: string
		description?: string
		publications: PublicationSummary[]
	}>(),
	{},
)
</script>

<template>
	<div class="featured-section__wrapper">
		<div class="featured-section__description" v-if="title">
			<CollectionText
				:title="title"
				:html="true"
				:text="description"
				class="mb-0 md:mb-16"
			/>
		</div>

		<CollectionFeaturedStack :publications="props.publications" />
	</div>
</template>

<style scoped>
.featured-section__wrapper {
	@apply w-full h-full md:flex md:flex-row max-w-5xl  mx-auto md:pt-16 md:h-[510px];
}

.featured-section__description {
	@apply mx-auto md:mx-0 mt-6 sm:px-8 md:mr-[10%] lg:mr-[15%] md:mt-0 md:h-full w-[440px] max-w-full md:flex flex-col justify-center items-center;
}
</style>
