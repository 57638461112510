<script setup lang="ts">
import type { PublicationSummary } from '~/components/card/index.vue'
import {
	CollectionDocument,
	type CollectionQuery,
} from '~/generated/operations'

const props = withDefaults(
	defineProps<{
		label?: string
		title?: string
		description?: string
		slug: string
		type: 'stack' | 'carrousel'
	}>(),
	{
		label: 'Uitgelicht door de redactie',
		type: 'carrousel',
	},
)

const { data } = await useCachedQuery<CollectionQuery>({
	key: `collection:${props.slug}`,
	query: CollectionDocument,
	variables: {
		slug: props.slug,
	},
})

type FeaturedCollection = Extract<
	NonNullable<CollectionQuery['entry']>,
	{ __typename: 'collection_featured_Entry' }
> & {
	featuredToPublication: PublicationSummary[]
}

const collection = !data.value?.entry
	? undefined
	: (data.value?.entry as FeaturedCollection)

const publications = collection?.featuredToPublication.filter(
	(p) => !p.hideCard,
)
const title = props.title ?? collection?.title
const description = props.description ?? collection?.description
</script>

<template>
	<ComponentSection
		color="sand"
		:label="label ?? collection.prefix"
		:class="`featured-section featured-section--${type}`"
		v-if="publications && publications.length > 0"
	>
		<CollectionSingle
			v-if="publications.length === 1"
			:title="title"
			:description="description"
			:publication="publications[0]"
		/>

		<CollectionFeaturedStackPage
			class="featured-stack"
			v-if="type === 'stack' && publications.length > 1"
			ref="cards"
			:title="title"
			:description="description"
			:publications="publications"
		/>

		<CollectionFeaturedCarrouselPage
			v-if="type === 'carrousel' && publications.length > 1"
			ref="cards"
			:title="title"
			:description="description"
			:publications="publications"
		/>
	</ComponentSection>
</template>

<style lang="postcss" scoped>
.featured-section {
	@apply relative overflow-hidden text-center;

	&--stack {
		@apply sm:pb-12;
	}
}

.featured-stack {
	@apply pt-0;
}
</style>
